import {DOCUMENT} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {environment} from 'environments';
const {backend} = environment;

@Component({
  selector: 'fb-root',
  templateUrl: 'main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  private document = inject(DOCUMENT);

  public ngOnInit(): void {
    const preconnectLink = this.document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = backend;
    preconnectLink.setAttribute('crossorigin', '');
    this.document.head.append(preconnectLink);
  }
}
