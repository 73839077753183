import type {Route, Routes} from '@angular/router';
import {guard} from 'app/core';

const shellRoute: Route = {
  path: '',
  canActivate: [guard(['access'], ['auth-error'])],
  loadChildren: () =>
    import('../shell/shell.module').then((m) => m.ShellModule),
};

export const routes: Routes = [shellRoute];
